import { Dispatch } from 'redux';
import { dependencyContainer } from '../../..';

import { SignInInteractor } from '../../../domain/modules/auth/useCase/SignInUseCase';
import ReduxDispatch from '../../presenter/ReduxDispatch';
import SignInPresenter from '../../presenter/signIn/SignInPresenter';
import { RootStore } from '../Store';
import { SIGN_IN_INPUTS, SignInInputsModel, SIGN_IN_LOADING } from './SignInActionTypes';

export const ChangeSignInInputs = (payload: SignInInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: SIGN_IN_INPUTS,
        payload
    })
}

export const SignInAction = () => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: SIGN_IN_LOADING
    })
    
    const { email, password } = getState().signInReducer;
    const gateway = dependencyContainer.dependency.gatewayFactory.getSignInGateway()
    new SignInInteractor(new SignInPresenter(new ReduxDispatch(dispatch)), gateway)
        .signIn({ email, password })
}
