import { UserViewModel } from "../../presenter/user/viewModel/UserViewModel";
import { UserListActionTypes } from "./UserListActionTypes";
import { GET_USERS_LOADING, GET_USERS_SUCCESS, GET_USERS_FAIL } from "./UserListActionTypes";

interface UserListState {
    userList: UserViewModel[];
    loading: boolean;
    error?: string;
}

const defaultState: UserListState = {
    userList: [],
    loading: true
}

const userListReducer = (state: UserListState = defaultState, action: UserListActionTypes): UserListState => {
    switch (action.type) {
        case GET_USERS_LOADING:
            return { ...state, loading: true }
        case GET_USERS_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case GET_USERS_SUCCESS:
            return { ...state, loading: false, userList: action.payload.userList }
        default:
            return state;
    }
}

export default userListReducer;