import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import BasePage from "../../page/basePage/BasePage";

import { RootStore } from "../../redux/Store";
import Loader from "../Loader";
import AuthorizedRoutes from "./AuthorizedRoutes";
import NotAuthorizedRoutes from "./NotAuthorizedRoutes";

export default function AppRouter() {
  const { signedIn, ready } = useSelector((state: RootStore) => ({
    ready: state.baseReducer.ready,
    signedIn: state.baseReducer.signedIn,
  }));

  const routes = () =>
    signedIn ? <AuthorizedRoutes /> : <NotAuthorizedRoutes />;

  return (
    <Router>
      <Route path="*" component={BasePage} />
        {ready ? routes() : <Loader />}
    </Router>
  );
}
